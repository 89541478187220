
<template>
  <div class="Aftershou_D">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect_top">
        <el-menu-item index="1">初级职称</el-menu-item>
        <el-menu-item index="2">中级职称</el-menu-item>
        <el-menu-item index="3">高级职称</el-menu-item>
        <el-menu-item index="4">职称论文</el-menu-item>
        <el-menu-item index="5">职称学时</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：为售后服务老师量身打造的板块，更好提升服务效率，提高服务品质
      </div>

    </div>

    <div class="tab_Bor"> 
      <div class="T1 bord">
        <span style="float:left;">学时类型：</span>
        <div class="T_sele" >
            <el-select v-model="id_three" placeholder="全部">
              <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
            </el-select>
        </div>
        <div style="flex:1">
          <span style="float:left;">站点：</span>
        <div class="T_sele" >
          <el-select v-model="id_eight" placeholder="全部">
            <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
          </el-select>
        </div>
        </div>
        <div style="flex:2">
          <span style="float:left">创建时间：</span>
          <div style="float:left;" class="Topo Tuyiiu">
            <el-date-picker v-model="Months1" type="date" placeholder="选择月份" :clearable="false" style="width:217px"></el-date-picker>
            <span style="margin: 0 15px 0 15px;display:inline">至</span>
            <el-date-picker v-model="Months2" type="date" placeholder="选择月份" :clearable="false" style="width:217px"></el-date-picker>
          </div>
        </div>
        <div style="flex:0.8"></div> 
      </div>
      <div class="T1">
        <div style="flex:1">
          <span style="float:left">业务员：</span>
        <div class="T_sele" >
          <el-select v-model="id_four" placeholder="请选择业务员">
            <el-option v-for="item in Fwus_list" :key="item.id" :label="item.username"  :value="item.id"></el-option>
          </el-select>
        </div>
        </div>
        <div style="flex:0.8">
          <div class="Actit" @click="AddXs(4)"  style="margin-left:15px;cursor: pointer;">查询</div>
          <div class="Actit dsdsdsdaaa"  @click="AddXs(3)">重置</div>
        </div> 
        <div style="flex:1"></div>
        <div style="flex:1"></div>
        <div style="flex:1"></div>

      </div>
    </div>
    <div class="Tables">
      <div class="T_sele dasdas">
          <el-select v-model="type_Self" placeholder="全部" @change="Chang_ses" style="width:90%">
              <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
      </div>
       <div class="Tab_T" @click="AddXs(5)" v-if="TextMoMo('workbench:sales:study:add')"><i class="el-icon-plus"></i>人员登记</div>
      <!-- <div class="Tab_T Tab_y" @click="Add_Del(5)"><i class="el-icon-document" ></i>批量认领</div> -->
      <div class="Tab_T Tab_y" style="border: 1px solid #FF4D4F;color:#FF4D4F"
        v-if="TextMoMo('workbench:sales:study:del')"  @click="Add_Del(2)"><i class="el-icon-delete"></i>批量删除</div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div v-if="TextMoMo('workbench:sales:study')">
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="55"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="55"
              align="center"></el-table-column>
            <el-table-column align="center" prop="username" label="姓名"></el-table-column>
            <el-table-column align="center" prop="business.username" label="业务员"></el-table-column>
            <el-table-column align="center" prop="site.name" label="站点"></el-table-column>
            <el-table-column align="center" prop="company" label="公需课"></el-table-column>
            <el-table-column align="center" prop="special_num" label="专业课"></el-table-column>
            <el-table-column align="center" prop="special_platform_text" label="高研班"></el-table-column>
            <el-table-column align="center" prop="mobile" label="手机号码"></el-table-column>

            <el-table-column label="操作" align="center" width="300">
              <template #default="scope" >
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(2, scope.row)" v-if="TextMoMo('workbench:sales:study:read')">查看</el-button>
                    <!-- <el-button type="text" v-if="!TextMoMo('workbench:sales:study:read')">查看</el-button> -->
                  </div>
                  <div class="el_butto">
                    <el-button type="text" @click="Handlbchak(1, scope.row)" v-if="TextMoMo('workbench:sales:study:edit')">编辑</el-button>
                  </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text" v-if="TextMoMo('workbench:sales:study:del')"
                    class="red"
                    @click="HanDel(scope.$index, scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block"   v-if="type_Self == 'self'">
            <el-pagination
              background
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
          <div class="block" v-if="type_Self == 'common'">
            <el-pagination
              background 
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>
    </div>

    <!--添加线索来源-->
    <el-dialog title="添加线索来源" v-model="dialogVisible3" width="36%">
      <div class="Tabbl">
        <el-table
        :data="Categ"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch 
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--添加线索来源-->

    <!--全部线索类型管理-->
    <el-dialog title="内容管理" v-model="dialogVisible2" width="36%"> 
      <div class="Tabbl">
        <el-table
        :data="Categ2"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input type="text" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="输入内容"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete2(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>

      <div class="Y_ids" @click="Add_leibie2"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN2()">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--全部线索类型管理-->

    <!-- 业务进度编辑 -->
    <el-dialog title="查看合同" v-model="editVisible2" width="47%" class="">
      <div style="height:500px;" >
        <el-scrollbar>
          <div class="rightPanel" style="max-width:835px;height:842px;box-shadow:none">
            <div class=" Riyy">
              <div class="Ri_tit">基本信息</div>
              <div class="Riidid" style="display: flex">
                  <div>
                      <div class="Rig_D" style="margin-right:15px">
                          <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 姓名</div>
                          <div class="Rtty" >
                          <el-input v-model="Customer.username" placeholder="请填写客户姓名" disabled class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div>
                      <div class="Rig_D" style="margin-right:15px">
                          <div class="Rig_Ddd" style="margin-bottom:4px"><span>*</span> 联系方式</div>
                          <div class="Rtty">
                          <el-input v-model="Customer.mobile" placeholder="请填写客户手机号码" disabled class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div>
                      <div class="Rig_D">
                          <div class="Rig_Ddd" style="margin-bottom:4px">客户昵称</div>
                          <div class="Rtty">
                          <el-input v-model="Customer.username" placeholder="请填写客户使用的昵称" disabled class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="Ri_tit" >基本信息</div>
              <div class="Ri_cen" style="width:98%">
                  <div>
                    <span>所有的订单</span>
                    <span>{{Tongji.total}}</span>
                  </div>
                  <div>
                    <span>未完成订单</span>
                    <span>{{Tongji.unfinish}}个</span>
                  </div>
                  <div>
                    <span>已退费的订单</span>
                    <span>{{Tongji.refund}}个</span>
                  </div>
              </div>
              <div class="Ri_bord">
                <el-table
                  :data="OrderList"
                  border
                  align="center"
                  style="width: 100%; margin-top: 20px">
                  <el-table-column
                    prop="createtime"
                    label="购买时间" align="center"
                    width="110">
                  </el-table-column>
                  <el-table-column 
                    prop="order_no" align="center" width="150"
                    label="订单号">
                  </el-table-column>
                  <el-table-column
                    prop="goodsone.goods_name" align="center" width="120"
                    label="购买项目">
                  </el-table-column>
                  <el-table-column
                    prop="goodsone.business_username"  width="102" align="center"
                    label="服务商">
                  </el-table-column>
                  <el-table-column
                    prop="pay_type_text" align="center" width="90"
                    label="支付方式">
                  </el-table-column>          
                  
                  <el-table-column
                    prop="pay_price" align="center" width="90"
                    label="支付金额">
                  </el-table-column>  
                  <el-table-column
                    prop="order_status_text" align="center" width="80"
                    label="订单状态">
                  </el-table-column>   
        
                  <el-table-column label="操作" align="center"  width="70">
                    <template #default="scope">
                      <div class="mmim" @click="handleEdit(1, scope.row)">查看</div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="Ri_tj" style="width:100%">添加</div>
              </div>
              <div class="Ri_tit" style="margin-top:18px">附加资料</div>
              <div class="Ri_bord">
                <el-table
                  :data="FileList"
                  border
                  style="width: 100%; margin-top: 20px">
                  <el-table-column
                    prop="createtime" align="center"
                    label="上传时间">
                  </el-table-column>
                  <el-table-column
                    prop="name" align="center"
                    label="文件名称">
                  </el-table-column>
                  <el-table-column
                    prop="filesize" align="center" width="100"
                    label="文件大小">
                  </el-table-column>
                  <el-table-column
                    prop="staff.realname" align="center" width="100"
                    label="上传者">
                  </el-table-column>
                  <el-table-column label="操作" align="center">
                    <template #default="scope">
                      <div class="mui">
                        <div class="mmim " @click="handleEdit2(1, scope.row)">查看</div>
                        <!-- <div class="mmim " @click="handleEdit2(2,scope.row)"> 下载</div> -->
                        <div class="mmim "> 
                          <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                        </div>
                        <div class="mmim " @click="handleEdit2(3, scope.row)">删除</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="Ri_tj" @click="TJ2(Customer.id)" style="width:100%">添加</div>
              </div>
            </div>

          </div>
        </el-scrollbar>
      </div>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible2 = false" style="margin-right:15px">取 消</el-button>
            <!-- <el-button type="primary" @click="saveEdit2()">确 认</el-button> -->
          </span>
        </template>
    </el-dialog>
    <!-- 业务进度编辑 -->

    <!-- 历时进度信息 -->
    <el-dialog title="历时进度信息" v-model="editVisible4" width="32%" class="">
        <div class="TRRE" style="margin:0px 24px 0 1px;height:300px" v-if="Flow_list2.length != 0">
            <el-scrollbar height="100%">
              <div id="time-line">
                <div class="item" v-for="item in Flow_list2" :key="item.index">
                  <div class="ytyt">
                    <div class="item-index">{{ item.createtime }}</div>
                    <div class="item-content">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
        </div>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible4 = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="saveEdit2()">确 认</el-button>
          </span>
        </template>
    </el-dialog>
    <!-- 历时进度信息 --> 

    <!-- 转移 -->
    <el-dialog title="转移" v-model="editVisible3" width="30%" class="">
        <el-form ref="form" label-width="100px">
          <div class="DDGGD">
            <el-form-item label="订单编号：">
              <el-input v-model="TA_name222" placeholder="填写订单编号"></el-input>
            </el-form-item>
            <el-form-item label="商品名称：">
               <el-input v-model="TA_name111" placeholder="填写商品名称"></el-input>
            </el-form-item>
            <el-form-item label="服务商：">
              <div class="Rtty Riiiu">
                <el-select v-model="TA_name333" placeholder="请选择可见员工">
                    <el-option v-for="item in Fwus_list" :key="item.staff_id" :label="item.realname" :value="item.staff_id"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="editVisible3 = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="saveEdit3()">确 认</el-button>
          </span>
        </template>
    </el-dialog>
    <!-- 转移 -->


    <!-- 导入 -->
    <el-dialog title="导入资料" v-model="dialogVisible7" width="31%" class="">
        <el-form ref="form" label-width="120px">
          <div class="DDGGD">

          <el-form-item label="选择导入文件：" style="margin-bottom:5px">
            <div class="el-form-item__content">
              <el-upload
                  accept=".xls,.xlsx"
                  :action="upload"
                  :headers="heads"
    
                  :multiple="false"
                  :file-list="fileList2"
                  :on-success="UP_Success4"
                  :on-remove="fileRemove">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
              </el-upload>

          </div>
          </el-form-item>

          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:28px">
            <el-button @click="dialogVisible7 = false" style="margin-right:15px">取 消</el-button>
            <el-button type="primary" @click="File_Sure2()">确 认</el-button>
          </span>
        </template>
      

    </el-dialog>
    <!-- 导入 -->

    <!-- 创建线索 -->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
      <div class="rightPanel-background" />
      <div style="height:950px;width: 822px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel" style="width:822px;max-width: 822px;height: 830px;padding-bottom: 120px;">
            <div class="Rig_1" v-if="chakan111 == 1">创建人员<i class="el-icon-close" @click="Close"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_1" v-if="chakan111 == 0">编辑人员<i class="el-icon-close" @click="Close"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div style="margin:0 0 18px 24px " v-if="chakan111 == 0">
              <el-menu
                :default-active="acndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="1">人员信息</el-menu-item>
                <el-menu-item index="2">进程记录</el-menu-item>
                <el-menu-item index="3">办理进度</el-menu-item>
                <el-menu-item index="4">附件资料</el-menu-item>
              </el-menu> 
            </div>
            <div class="Rig_A" v-if="info_Show == 100">
              <div class="Rioo_c">
                <span>订单验证：</span>
                <el-input v-model="D_yanzheng" placeholder="请输入本次服务对象对应的订单号"></el-input>
                <span style="color:#1890FF;font-size:14px;float:right;margin-top:7px;cursor: pointer;" @click="Clyan">点击验证</span>
              </div>
              <div v-if="D_yan2 == 1" style="color: #262626;font-size: 14px;margin-top:45px">验证成功，系统有此订单，可查看详情</div>
              <div v-if="D_yan2 == 2" style="color: #262626;font-size: 14px;margin-top:45px">验证失败，系统查无订单，请重新输入</div>
              <div class="Rioo_c" v-if="D_yan2 == 1">
                <span>订单验证：</span>
                <el-input v-model="D_yanzheng_Order.order_no" placeholder="请输入本次服务对象对应的订单号" disabled></el-input>
                 <span style="color:#1890FF;font-size:14px;float:right;margin-top:7px;cursor: pointer;" @click="Ckakan">查看详情</span>
              </div>
              <div class="xinxi_B" >一、订单基本信息</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" >
                          <span style="color:#FF4D4F">* </span>买家姓名</div>
                        <div class="Rtty "> 
                          <el-input v-model="D_yanzheng_Order.goodsone.user_nickname" disabled placeholder="请验证订单号" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >买家联系方式</div>
                        <div class="Rtty ">
                          <el-input v-model="D_yanzheng_Order.goodsone.user_mobile" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >订单状态</div>
                        <div class="Rtty ">
                          <el-input v-model="D_yanzheng_Order.order_status_text" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 服务对象</div>
                        <div class="Rtty ">
                          <el-input v-model="val2" placeholder="请验证订单号" disabled class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >购买项目</div>
                        <div class="Rtty ">
                          <el-input v-model="D_yanzheng_Order.goodsone.goods_name" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >购买数量</div>
                        <div class="Rtty ">
                          <el-input v-model="D_yanzheng_Order.goodsone.total_num" disabled class="El_ii" placeholder="请验证订单号"></el-input>
                        </div>
                    </div>
                </div>
              </div> 
            </div>
            <div class="Rig_A" v-if="info_Show == 0">
              <div class="xinxi_B">一、基本信息</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>联系方式</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.mobile" disabled placeholder="请填写联系方式" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户姓名</div>
                        <div class="Rig_Rrr" @click="editVisible2 = true">查看合同</div>
                        <div class="Rtty "> 
                          <el-input v-model="Row.username" disabled class="El_ii" placeholder="请填写客户姓名"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 身份证号码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.id_card" class="El_ii" placeholder="请填写身份证号码"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" style="margin-bottom:8px;width:240px"> 
                          <span style="color:#FF4D4F">* </span>工作单位（与申报单位一致）</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.company" placeholder="请选择购买项目" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 所属站点</div>
                        <el-select v-model="Row.site_id" placeholder="请选择所属站点" disabled>
                          <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>所属业务人员</div>
                        <el-select v-model="Row.business_id" placeholder="请选择所属业务人员" disabled>
                          <el-option v-for="item in Fwus_list" :key="item.id" :label="item.username"  :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">二、公需课学时</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>学时类型</div>
                          <el-select v-model="Row.common_type" placeholder="请选择学时类型">
                            <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
                          </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 公需课学时数</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.common_num" placeholder="请填写公需课学时数" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 学习平台</div>
                        <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                        <el-select v-model="Row.common_platform" placeholder="请公需课学时数">
                          <el-option v-for="item in NNN_1" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 学习平台账号</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.common_username" placeholder="请填写正确的登录账号信息" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >学习平台密码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.common_password" class="El_ii" placeholder="请输入相对应的网报平台密码"></el-input>
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">三、专科课学时</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>学时类型</div>
                          <el-select v-model="Row.special_type" placeholder="请选择学时类型">
                            <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
                          </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 专业课学时数</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.special_num" placeholder="请填写专业课学时数" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 学习平台</div>
                        <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                        <el-select v-model="Row.special_platform" placeholder="请选择学习平台">
                          <el-option v-for="item in NNN_1" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 学习平台账号</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.special_username" placeholder="请填写正确的登录账号信息" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >学习平台密码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.special_password" class="El_ii" placeholder="请输入相对应的网报平台密码"></el-input>
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">四、高研班学时</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>学时类型</div>
                          <el-select v-model="Row.senior_type" placeholder="请选择学时类型">
                            <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
                          </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 高研班学时数</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.senior_num" placeholder="请填写高研班学时数" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 学习平台</div>
                        <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                        <el-select v-model="Row.senior_platform" placeholder="请选择学习平台">
                          <el-option v-for="item in NNN_1" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 学习平台账号</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.senior_username" placeholder="请填写正确的登录账号信息" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >学习平台密码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.senior_password" class="El_ii" placeholder="请输入相对应的网报平台密码"></el-input>
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">五、备注信息</div>
              <div class="Rig_D" style="width:100%">
                  <div class="Rig_Ddd">描述（选填）：</div>
                  <div class="Rtty">
                      <el-input type="textarea" style="width:96.5%" v-model="Row.remark" placeholder="请填写描述信息" ></el-input>           
                  </div>
              </div>
              <div class="BCSZ" style="margin-right:24px;" @click="Btn_cj(1)" v-if="chakan111 == 1">创建人员</div>
              <div class="BCSZ" style="margin-right:24px;" @click="Btn_cj(2)" v-if="chakan111 == 0">保存设置</div>
              <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:30px" @click="show1 = false">取消</div>
            </div>
            <div class="Rig_A"  v-if="info_Show == 1">
                  <div class="Rig_D Tigtgg R_texxt" style="width:100%">
                    <div class="TRRE">
                      <div style="margin:8px 0 9px 0;font-size: 14px;color: #333333;">全部进程信息</div>
                        <div style="margin:20px 24px 0 1px;height:200px;" v-if="Flow_list.length != 0">
                            <el-scrollbar height="100%">
                              <div id="time-line">
                                <div class="item" v-for="item in Flow_list" :key="item.index">
                                  <div class="ytyt">
                                    <div class="item-index">{{ item.createtime }}</div>
                                    <div class="item-content">{{ item.info }}</div>
                                    <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                                  </div>
                                </div>
                              </div>
                            </el-scrollbar>
                      </div>

                    </div>
                    <div class="Rig_D" style="position: relative;width:100%">
                        <div class="Rig_Ddd" style="margin-bottom:9px;margin-top:30px;"> <span style="color:#FF4D4F">*</span> 文字记录</div>
                        <div class="Rtty">
                            <el-input type="textarea" style="" v-model="Info_desc" 
                            placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率
① 选择常用语更新记录
② 手动输入文字信息
③ 将��要的凭证、历史沟通、其他社交媒体截图等在下方上传保存"></el-input>           
                        </div>     
                      <div class="Rtty Riuo fdgfg" style="clear:both;"> 
                        <div style="margin:4px 23px 0 0;float:right">
                            <el-checkbox v-model="valueA_input1" label="同步给客户"></el-checkbox>
                        </div>
                      </div>
                    </div>  
                    <div>
                      <div class="Rig_Ddd" style="margin:28px 0 9px 0">图片线索（选填）</div>
                      <div class="Rtty TUUu" style="clear:both"> 
                          <div style="margin-top:20px">
                            <el-upload
                              :action="upload"
                              :headers="heads"
                              list-type="picture-card"
                              :on-success="UP_Success2"
                              :on-remove="HAndemove2"
                              :on-preview="handlePictureCardPreview">
                              <i class="el-icon-plus"></i>
                              <div class="el_asff">上传图片</div>
                            </el-upload>
                          </div>
                      </div>
                    </div> 
                    <div class="Rtty">
                        <div class="BCSZ" style="margin-right:24px" @click="Btn_dsa">更新进程</div>
                        <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">取  消</div>
                    </div>
                  </div> 

            </div>
            <div class="Rig_A" v-if="info_Show == 2" style="padding-right: 30px;">
                    <div style="overflow:hidden" class="Rioiio">
                      <div class="Righh_q"  v-for="item in ProressList" :key="item.index">
                        <div class="Rdffdf">{{item.title}}</div>
                        <div>
                          <el-progress :text-inside="true" :stroke-width="26" :percentage="item.progress"></el-progress>
                        </div>
                        <div class="Riddfsa" :class="idw == item.id ? 'Ridd_activ' : ''" >{{item.content}}</div>
                        <div class="Riddfsa2" style="margin-right:13px" v-if="idw != item.id" @click="Zhankai(item.id)">展开</div>
                        <div class="Riddfsa2" style="margin-right:13px" v-if="idw == item.id" @click="Zhankai(item.id)">收回</div>
                        <div class="Riddfsa2" @click="Lishi(item)">历时进度</div>
                      </div>

                    </div>

                    <div class="BCSZ" style="margin-right:30px" @click="Bijjij">编辑</div>
                    <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;" @click="show1 = false">取消</div>
            </div>
            <div v-if="info_Show == 3">
                        <div class="Ri_bord">
                          <el-table
                            :data="FileList_TEXT"
                            border
                            style="width: 93%; margin: 20px 0 0 28px">
                            <el-table-column
                              prop="createtime" align="center"
                              label="上传时间">
                            </el-table-column>
                            <el-table-column
                              prop="name" align="center"
                              label="文件名称">
                            </el-table-column>
                            <el-table-column
                              prop="filesize" align="center" width="100"
                              label="文件大小">
                            </el-table-column>
                            <el-table-column
                              prop="staff.realname" align="center" width="100"
                              label="上传者">
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                              <template #default="scope">
                                <div class="mui">
                                  <div class="mmim " @click="handleEdit2(1, scope.row)">查看</div>
                                  <!-- <div class="mmim " @click="handleEdit2(2,scope.row)"> 下载</div> -->
                                  <div class="mmim "> 
                                    <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                                  </div>
                                  <div class="mmim " @click="handleEdit2(3, scope.row)">删除</div>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                          <div class="Ri_tj" @click="TJ2()" style="width:93%;margin-left:28px">添加</div>
                        </div>
                
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!-- 创建线索 -->

    <!-- 查看线索 -->
    <div ref="rightPanel" v-if="show11" class="rightPanel-container show">
      <div class="rightPanel-background" />
      <div style="height:950px;width: 822px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel" style="width:822px;max-width: 822px;height: 830px;padding-bottom: 120px;">
            <div class="Rig_1">查看人员<i class="el-icon-close" @click="Close"  style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_A">
              <div class="xinxi_B">一、基本信息</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>联系方式</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.mobile" disabled placeholder="请填写联系方式" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 客户姓名</div>
                        <div class="Rig_Rrr" @click="editVisible2 = true">查看合同</div>
                        <div class="Rtty "> 
                          <el-input v-model="Row.username" disabled class="El_ii" placeholder="请填写客户姓名"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 身份证号码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.id_card" disabled class="El_ii" placeholder="请填写身份证号码"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" style="width:240px;"> 
                          <span style="color:#FF4D4F">* </span>工作单位（与申报单位一致）</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.company" disabled placeholder="请选择购买项目" class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 所属站点</div>
                        <el-select v-model="Row.site_id" placeholder="请选择所属站点" disabled>
                          <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>所属业务人员</div>
                        <el-select v-model="Row.business_id" placeholder="请选择所属业务人员" disabled>
                          <el-option v-for="item in Fwus_list" :key="item.id" :label="item.username"  :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">二、公需课学时</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>学时类型</div>
                          <el-select v-model="Row.common_type" placeholder="请选择学时类型" disabled>
                            <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
                          </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 公需课学时数</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.common_num" placeholder="请填写公需课学时数" disabled class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 学习平台</div>
                        <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                        <el-select v-model="Row.common_platform" placeholder="请公需课学时数" disabled>
                          <el-option v-for="item in NNN_1" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 学习平台账号</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.common_username" placeholder="请填写正确的登录账号信息" disabled class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >学习平台密码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.common_password" class="El_ii" placeholder="请输入相对应的网报平台密码" disabled></el-input>
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">三、专科课学时</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>学时类型</div>
                          <el-select v-model="Row.special_type" placeholder="请选择学时类型" disabled>
                            <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
                          </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 专业课学时数</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.special_num" placeholder="请填写专业课学时数" class="El_ii" disabled></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 学习平台</div>
                        <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                        <el-select v-model="Row.special_platform" placeholder="请选择学习平台" disabled>
                          <el-option v-for="item in NNN_1" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 学习平台账号</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.special_username" placeholder="请填写正确的登录账号信息" disabled class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >学习平台密码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.special_password" class="El_ii" placeholder="请输入相对应的网报平台密码" disabled></el-input>
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">四、高研班学时</div>
              <div class="Diidd_Yi">
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 
                          <span style="color:#FF4D4F">* </span>学时类型</div>
                          <el-select v-model="Row.senior_type" placeholder="请选择学时类型" disabled>
                            <el-option v-for="(item, i) in BBBBB_list" :key="i" :label="item" :value="i"></el-option>
                          </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 高研班学时数</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.senior_num" placeholder="请填写高研班学时数" disabled class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" ><span style="color:#FF4D4F">* </span> 学习平台</div>
                        <div class="Rig_Rrr" @click="NNNNN(1)">内容管理</div>
                        <el-select v-model="Row.senior_platform" placeholder="请选择学习平台" disabled>
                          <el-option v-for="item in NNN_1" :key="item.value" :label="item.content" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia">
                        <div class="Rig_Ddd" > 学习平台账号</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.senior_username" placeholder="请填写正确的登录账号信息" disabled class="El_ii"></el-input>
                        </div>
                    </div>
                </div>
                <div class="yiiiuyt">
                    <div class="Rig_D Ruuia3">
                        <div class="Rig_Ddd" >学习平台密码</div>
                        <div class="Rtty ">
                          <el-input v-model="Row.senior_password" class="El_ii" placeholder="请输入相对应的网报平台密码" disabled></el-input>
                        </div>
                    </div>
                </div>
              </div>
              <div class="xinxi_B">五、备注信息</div>
              <div class="Rig_D" style="width:100%">
                <div class="Rig_Ddd">描述（选填）：</div>
                <div class="Rtty">
                    <el-input type="textarea" style="width:96.5%" v-model="Row.remark" placeholder="请填写描述信息" disabled></el-input>           
                </div>
              </div>
              <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:30px" @click="show11 = false">取消</div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!-- 查看线索 -->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import { fetchData } from "../../api/index";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  name: "basetable",
  data() {
    return {
      show11:false,
      Customer:[],
      Tongji:{},
      OrderList:[],
      FileList:[],
      options: regionData,
      selectedOptions: [],
      D_yanzheng:'',
      D_yanzheng_Order:{
        goodsone:{},
        order_status_text:'',
      },
      D_yan1:0,
      D_yan2:0,
      Customer_id:0,
      TA_name11:'',
      TA_name111:'',
      TA_name1_Arr:[],
      val2:'买家本人',    
      TA_name22:'',
      TA_name222:'',
      TA_name333:'',

      Flow_list:[],    
      Flow_list2:[],    
      info_Show:0,
      checked_kehu:false,
      activeIndex: '5',
      acndex:'1',
      dialogImageUrl: '',
      radio:'1',

      show1:false,
      dialogVisible2: false,  
      dialogVisible3: false,  
      dialogVisible4: false,
      multipleSelection_id:null,   //勾选的信息
      multipleSelection:[],
      Ta_Shsh:0,

      tableData: [],
      total1:0,
      total2:0,
      currentPage1:0,
      currentPage2:0,

      idW:0,
      idY:0,
      idG:0,

      Categ:[],
      Categ2:[],
      input_seach:'',
      type_Self: 'self',
      dialogVisible3_e:0,
      dialogVisible2_e:0,

      Config1:[],
      Config2:[],
      Config3:[],
      Config4:[],
      Config5:[],
      Config6:[],

      Months3:'',
      Months4:'',

      id_one:'',
      id_tow:'',
      id_three:'',
      id_four:'',
      id_five:'',
      id_six:'',
      id_seven:'',
      id_eight:'',

      Case_attse:[],  //可见部门
      chakan111: 0,
      Hand_id:0,
      Customer_id:0,

      SiteList:[],      //站点列表
      person:[
        {mobile:'',username:'',company:'',id_card:''}
      ],        //服务对象
      pay_form:[{id:'all',name:'全款'},{part:'all',name:'部分'}],

      company_type:[{id:'10',name:'国有企业'},{id:'20',name:'国有控股企业'},{id:'30',name:'外资企业'},{id:'40',name:'合资企业'},{id:'50',name:'民营企业'}], //单位性质
      social_security_type:[{id:'10',name:'个人参保'},{id:'20',name:'单位参保'}], //参保类型
      social_security_situation:[{id:'10',name:'一致'},{id:'20',name:'不一致'}], //单位性质
      education_form:[{id:'10',name:'全日制'},{id:'20',name:'非全日制'}], //学历形式
      education_arrangement:[{id:'10',name:'博士'},{id:'20',name:'硕士'},{id:'30',name:'本科'},{id:'40',name:'专科（高职）'},{id:'50',name:'高中（中专）'},{id:'60',name:'初中'},
        {id:'70',name:'小学'}], //学历层次
      study_time:[{id:'10',name:'160学时'},{id:'20',name:'360学时'}],//继续教育状况
      study_common:[{id:'10',name:'满足'},{id:'20',name:'不满足'}],  //公共课状况
      study_special:[{id:'10',name:'满足'},{id:'20',name:'不满足'}],  //专业课状况
      
      radio1:'天',
      valueA_3:'',
      valueA_sele1:'',
      valueA_sele2:'',

      Row_Eurl:[],          //图片
      Row_Eurl2:[],          //图片
      Info_desc:'',
      Claim:{},

      File:'',
      File_Yid:[],
      File_Bid:[],
      fileList:[],

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      upload2:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},

      MMD:[{id:'self',name:'我的订单'},{id:'common',name:'全部订单'}],
      editVisible:false,
      editVisible2:false,
      editVisible3:false,
      editVisible4:false,
      Customer_Self:'',
      StaFFList:['20201210529798485130'],    ///////////// 全部员工列表
      DementList:[],   ///////////// 全部部门列表

      Fwus_list:[],
      ConfigList:{},
      Row: {
        username: "", //申报人姓名
        mobile: "", //申报人联系方式
        id_card: "", //申报人身份证号
        company: "", //工作单位
        company_type: "", //注册公司类型
        site_id: "", //所属站点
        business_id: "", //服务商ID
        order_id: "", //订单ID
        customer_id: "", //客户ID
        common_type: "", //公需课学时类型
        common_num: "", //公需课学时数
        common_platform: "", //公需课学习平台
        common_username: "", //公需课平台账号 
        common_password: "", //公需课平台密码
        special_type: "", //专业课学时类型
        special_num: "", //专业课学时数量
        special_platform: "", //专业课学习平台
        special_username: "", //专业课平台账号
        special_password: "", //专业课平台密码
        senior_type: "", //高研班类型
        senior_num: "",     //高研班学时数量
        senior_platform: "", //高研班学习平台
        senior_username: "", //高研班平台账号
        senior_password: "", //高研班平台密码
        remark: "" //备注信息
      },
      ProressList:[],
      ProressList_W:[],
      idw:0,
      FileList:[],
      dialogVisible7:false,
      File2:null,
      fileList:[],
      fileList2:[],
      File2_name:'',
      NNN_1:'',
      NNN_2:'',
      NNN_3:'',
      NNN_4:'',
      BBBBB_list:{},
      Order_num:'',
      Months1:'',
      Months2:'',
      FileList_TEXT:[]
    };
  },
  mounted() {
    var that = this
    that.AAAAA()
    that.BBBBB()
    that.changeRTY()
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/ajax/staffList',{   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
              that.StaFFList = res.data.data
          }
    })  
    axios.get('/admin/company/department/index',{   // 全部部门列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DementList = res.data.data.rows
        }
    })
    axios.get('/admin/ajax/allBusiness',{   // 服务商列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.Fwus_list = res.data.data
          console.log(that.Fwus_list)
        }
    })
    axios.get('/admin/ajax/businessList',{   // 服务商
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.BusinessList = res.data.data
        }
    })

    

    axios.get('/admin/customer/config/index',{   // 全部部门列表
            params:{
              type: 'order'
            }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.ConfigList = res.data.data.config
        }
    })  
    axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
        }
    })     
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Handlbchak(e,data) {      // 编辑
    console.log(data)
      var that = this
      that.Hand_id = data.id
      that.Customer_id = data.customer_id
      that.chakan111 = 0
      that.show1 = true
      if (e == 1) {
        that.info_Show = 0
        that.acndex = 1
      }
      if (e == 2) {
        that.show11 = 11
      }
      axios.get('/admin/workbench/sales/studyInfo',{   // 线索详情
            params:{
              id: data.id
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败1');  
          } else {
              that.Row = res.data.data.row
              console.log(res.data.data)
              that.Order_num = res.data.data.order.order_no
          }
      })
        axios({                                               //业务进度信息
          method: 'post',
          url: '/admin/workbench/sales_info/progressList', 
          data: {
            type:'study',
            source_id:data.id
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.ProressList = res.data.data.proressList
            that.ProressList_W = res.data.data.proressList.slice()   //复制一个数组 
            console.log(res.data.data)
          }
        })
        axios({                                               //附件列表
          method: 'post',
          url: '/admin/workbench/sales_info/fileList', 
          data: {
            type:'study',
            source_id:data.id
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.FileList_TEXT = res.data.data.fileList
            console.log(res.data.data)
          }
        })
      axios.get('/admin/customer/flow/read',{   // 线索 进程信息
            params:{
              customer_id: data.customer_id,
              type: "study", 
              source_id: data.id,  
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败2');  
          } else { 
            that.Flow_list = res.data.data.flow_list

          }
      }) 

      axios.get('/admin/customer/contract/read',{   // 合同详情
            params:{
              customer_id: 1
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败3');  
          } else { 
              console.log(res.data.data)
              that.Customer = res.data.data.customer
              that.Tongji = res.data.data.tongji
              that.OrderList = res.data.data.orderList
              that.FileList = res.data.data.fileList
          }
      })
    },
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
    },
    Pro_bj() {
      var that = this
      var Pror = that.ProressList_W
      var arr = {
        id:0,
        title:'',
        progress:'',
        content:''
      }
      Pror.push(arr)
      that.ProressList_W = Pror
    },
    handleDelete2(index, row) {  // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.CGata
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.CGata = ATT
      }

    },
    HanDel(e, data) {       //删除
      var that = this 
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
      }).then(() => {
            axios.delete('/admin/workbench/sales/delStudy',{ 
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                that.AAAAA()
            }
          })            
      })     
    },

    changeRTY(e) {          //内容请求 四个
      var that = this
      axios.get('/admin/company/content/index',{   // 设置选项  申报地区
            params:{field_key: 'sales_study_platform'}
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{that.NNN_1 = res.data.data.enums}
      })      
      // axios.get('/admin/company/content/index',{   // 设置选项  申报系列
      //       params:{field_key: 'sales_declare_series'}
      //   }).then(function (res) {
      //     if (res.data.code == 0) {
      //       alert('请求失败');  
      //     } else{that.NNN_2 = res.data.data.enums}
      // })    
      // axios.get('/admin/company/content/index',{   // 设置选项 申报专业
      //       params:{field_key: 'sales_declare_major'}
      //   }).then(function (res) {
      //     if (res.data.code == 0) {
      //       alert('请求失败');  
      //     } else{that.NNN_3 = res.data.data.enums}
      // })    
      // axios.get('/admin/company/content/index',{   // 设置选项 评价方式
      //       params:{field_key: 'sales_evaluate_type'}
      //   }).then(function (res) {
      //     if (res.data.code == 0) {
      //       alert('请求失败');  
      //     } else{that.NNN_4 = res.data.data.enums}
      // })     
    },
    Add_fiwu(e) {
      var that= this
      var arr = that.person
      var att = {
          mobile:'',username:'',company:'',id_card:''
      }
      arr.push(att)
      that.person = arr
    },
    Clyan(e) {
      var that = this
        axios.get('/admin/order/order/getOrderbyOrderNo',{   // 设置选项 
              params:{
                order_no: that.D_yanzheng
              }
            }).then(function (res) {
              console.log(res.data.data)
            if (res.data.code == 0) {
              alert('请求失败');  
            } else{ 
              console.log(res.data.data.order)
                if (res.data.data.order == null) {
                  that.D_yan2  = 2
                  that.D_yanzheng_Order ={
                    goodsone:{},
                    order_status_text:'',
                  }
                } else if (res.data.data.order.length == 0){ 
                  that.D_yan2  = 2
                  that.D_yanzheng_Order ={
                    goodsone:{},
                    order_status_text:'',
                  }
                } else {
                  that.D_yan2  = 1
                  that.D_yanzheng_Order = res.data.data.order
                  that.Row.username = res.data.data.order.goodsone.user_nickname
                  that.Row.mobile = res.data.data.order.goodsone.user_mobile
                  that.Row.site_id = res.data.data.order.site_id
                  that.Row.business_id = res.data.data.order.business_id
                }

            }
        })        
    },

    Ckakan(e) {
      var that = this
      that.info_Show = 0
    },
    saveEdit2(e) {               //业务进度编辑确认
      var that = this
        axios({
          method: 'post',
          url: '/admin/workbench/sales_info/editProgress', 
          data: {
            type:'primary',
            source_id:that.Hand_id,
            content:that.ProressList_W
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else{
                that.editVisible2 = false
                that.ProressList = res.data.data.proressList
             }
        })
    },
    Bijjij(e) {                  //业务进度编辑
      var that = this
      that.editVisible2 = true
        axios({                                               //业务进度信息
          method: 'post',
          url: '/admin/workbench/sales_info/progressList', 
          data: {
            type:'primary',
            source_id:that.Hand_id
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.ProressList_W = res.data.data.proressList.slice()   //复制一个数组 
          }
        })      
    },
    Zhankai(e) {                   //办理进度  展开
      var that = this
      if (that.idw == e) {that.idw = 982} else {that.idw = e}
    },
    Lishi(e) {                    //办理进度 历史信息
      var that = this
      that.editVisible4 = true
      that.Flow_list2 = e.history
      console.log(e)
    },
    TJ2() {                         // 附件上传
      this.dialogVisible7 = true;
    },
    UP_Success4(e, fileList2) {                //附件上传成功
      var that = this
      console.log(e)
      console.log(fileList2.name)
      that.File2 = e.data.url
      that.File2_name = fileList2.name
    },
    File_Sure2() {              //附件上传确认
      var that = this
        axios({                                              
          method: 'post',
          url: '/admin/workbench/sales_info/addFile', 
          data: {
            row:{
              type:'primary',
              source_id:that.Hand_id,
              name:that.File2_name,
              filepath:that.File2
            }

          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.dialogVisible7 = false
            axios({                                               //附件列表
              method: 'post',
              url: '/admin/workbench/sales_info/fileList', 
              data: {
                type:'primary',
                source_id:that.Hand_id
              },
            }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              }else {
                that.FileList_TEXT = res.data.data.fileList
                console.log(res.data.data)
              }
            })
          }
        })
    },
    handleEdit2(e, data) {        //附件下载 删除
      var that = this
      if (e == 1) {

      } if (e == 2) {
        require.ensure([], () => {
          const { export_json_to_excel } = require('../../excel/Export2Excel');
          const tHeader = ['上传时间', '文件名称', '文件大小', '上传者'];
          // 上面设置Excel的表格第一行的标题
          const filterVal = ['createtime', 'name', 'filesize', 'staff.realname'];
          // 上面的index、nickName、name是tableData里对象的属性
          const list = this.FileList;  //把data里的tableData存到list
          const data2 = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data2, data.name);    
        })
      } if (e == 3) {
        that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
          }).then(() => {
              axios.delete('/admin/workbench/sales_info/delFile',{ 
                  params:{
                        id: data.id
                  }
                }).then(function (res) {
                if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
                } else{ 
                    that.$message.success(res.data.msg);
                    axios({                                               //附件列表
                      method: 'post',
                      url: '/admin/workbench/sales_info/fileList', 
                      data: {
                        type:'primary',
                        source_id:that.Hand_id
                      },
                    }).then(function (res) {
                      if (res.data.code == 0) {
                        that.$message.warning(res.data.msg);
                      }else {
                        that.FileList_TEXT = res.data.data.fileList
                        console.log(res.data.data)
                      }
                    })
                }
              })   
          })
     
      }
    },
    Btn_cj(e) {         //  确认按钮
      var that = this

       
      //  if(that.Row.graduate_time != '') {
      //    y1 = that.formatDate2(that.Row.publish_time_one);
      //    y2 = that.formatDate2(that.Row.publish_time_two);
      //    that.Row.publish_time_one = y1
      //    that.Row.publish_time_two = y1
      //  }
       
       console.log()
      if (that.chakan111 == 1) {
        that.Row.order_id = that.D_yanzheng_Order.id
        that.Row.customer_id = that.D_yanzheng_Order.customer_id
        axios({
          method: 'post',
          url: '/admin/workbench/sales/addStudy', 
          data: {
            row:that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.$message.success(res.data.msg);
            that.show1 = false
            that.AAAAA()  
          }
        })
        
      } else if (that.chakan111 == 0) {
        axios({
          method: 'put',
          url: '/admin/workbench/sales/editStudy', 
          data: {
            id: that.Hand_id,
            row:that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          }else {
            that.$message.success(res.data.msg);
            that.show1 = false
            that.AAAAA()  
          }
        
        })
      }
       
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../excel/Export2Excel');
        const tHeader = ['买家姓名', '联系方式', '订单号', '购买项目' , '支付金额', '剩余金额', '支付方式', '接单状态'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['username', 'mobile', 'order_no', 'goods_name', 'pay_price', 'unpaid_money', 'pay_type_text', 'confirm_status_text'];
        // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData;  //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '销售线索');    
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    beforeUpload(file) {                             // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
        //文件类型
        const isType = file.type === 'application/vnd.ms-excel'
        const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const fileType = isType || isTypeComputer
        if(!fileType) {
            this.$message.error('上传文件只能是xls/xlsx格式！')
        }

        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 10;
        if(!fileLimit) {
            this.$message.error('上传文件大小不超过10M！');
        }
        return fileType && fileLimit
    },

    UP_Success3(e) {                //图片上传成功
      var that = this
      console.log(e)
      that.File = e.data.url
      that.aTY = 0
      console.log(that.File)
    },
    fileRemove(file, fileList) {                    // 移除选择的文件
        if(fileList.length < 1) {
            this.uploadDisabled = true //未选择文件则禁用上传按钮
        }
        console.log(file)
    },

    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.show1 = false;
      this.show11 = false;
    },
    
    submitUpload() {
      this.$refs.upload.submit();
    },
    Btn_dsa() {                     //  进程提交
      var that = this
      axios({
          method: 'post',
          url: '/admin/customer/flow/add', 
          data: {
            row:{
              customer_id: that.Customer_id, //客户ID，客户管理时必填
              info: that.Info_desc, //进程的信息
              type: "study", //类型:order=线索,=客户,order=订单,contract=合同
              source_id: that.Hand_id, //当前类型所在列表ID
              images: that.Row_Eurl2
            }
          },
        }).then(function (res) {
          if(res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
              that.$message.success(res.data.msg);
              that.Info_desc = ''
              axios.get('/admin/customer/flow/read',{   // 线索 进程信息
                    params:{
                      customer_id: that.Customer_id,
                      type: "study", 
                      source_id: that.Hand_id,  
                    }
                }).then(function (res) {
                  if (res.data.code == 0) {
                    alert('请求失败');  
                  } else { 
                    that.Flow_list = res.data.data.flow_list

                  }
              }) 
          }
          
        })       
    },

    AddXs(e) {
      var that = this
       that.dialogVisible3_e = e

  
      if (e == 1) {
        that.dialogVisible3 = true
        that.Categ = that.leads_source_W
      } else if (e == 2) {
        that.dialogVisible3 = true
        that.Categ = that.leads_demand_W
      } else if (e == 3) {
        that.Months1 = ''
        that.Months2 = ''
        that.id_one = ''
        that.id_tow = ''
        that.id_three = ''
        that.id_four = ''
        that.id_five = ''
        that.id_six = ''
        that.id_seven = ''
        that.id_eight = ''
      } else if (e == 4) {
      var y1 = ''
      var y2 = ''
      if(that.Months1 != '') {
        y1 = that.formatDate(that.Months1);
      }
      if(that.Months2 != '') {
        y2 = that.formatDate(that.Months2);
      }
      axios({
        
        method: 'post',
        url: '/admin/workbench/sales/studyList', 
        data: {
          page: 1,
          offset:0,
          limit:10,
          // type: that.type_Self,
          filter:{
            study_type: that.id_three,                               //学时类型
            business_id: that.id_four,                        //业务员ID（服务商）
            site_id: that.id_eight,                            //站点ID
            time:{
              min: y1,
              max: y2,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {   
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })
      } else if(e == 5) {
        that.show1 = true
        that.info_Show = 100
        // that.info_Show = 0
        that.chakan111 = 1
        that.D_yanzheng_Order ={
        goodsone:{},
        order_status_text:'',
      }
        that.Row = {
          username: "", //申报人姓名
          mobile: "", //申报人联系方式
          id_card: "", //申报人身份证号
          company: "", //工作单位
          // company_type: "", //单位性质
          site_id: "", //所属站点
          business_id: "", //所属业务人员

          order_id: "",         //订单ID
          source_one: "",       //稿件来源（一）
          periodical_one: "",   //投稿期刊（一）
          publish_time_one: "", //发表时间（一）
          source_two: "",       //稿件来源（二）
          periodical_two: "",   //投稿期刊（二）
          publish_time_two: "", //发表时间（二）
          remark: "" //备注信息
        }
      }
    },
    NNNNN(e) {         //内容管理
      var that = this
      that.dialogVisible2 = true
      that.dialogVisible2_e = e
      var att = ''
      if (e == 1){att = 'sales_study_platform'} else if(e == 2) {att = 'sales_paper_periodical'}

      axios.get('/admin/company/content/index',{   // 设置选项 
            params:{
              field_key: att
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{
              that.Claim = res.data.data
              if (e == 1) {
                that.Categ2 = res.data.data.enums
              } else if(e == 2) {
                that.Categ2 = res.data.data.enums
              } else if(e == 3) {
                that.Categ2 = res.data.data.enums
              } else if(e == 4) {
                that.Categ2 = res.data.data.enums
              }
          }
      })

    },
    NabCH_BTN2() {     //内容管理确认 
      var that = this 
      var ATT = that.Categ2
      var e = that.dialogVisible2_e
      var att = ''
      if (e == 1){att = 'sales_study_platform'}else if(e == 2) {att = 'sales_paper_periodical'}

          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: att, //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
              } else {
                that.dialogVisible2 = false
                that.changeRTY()
              }
          })  
        
     
    },
    Add_leibie2() {            //内容新建
      var that = this
      var ATT = that.Categ2
      var srt = {
        content: "",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ2 = ATT
    }, 
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },
    formatDate2(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y
    },

    Activ(e, id) {
      var that = this
      var y1 = ''
      var y2 = ''
      if(that.Months1 != '') {
        y1 = that.formatDate(that.Months1);
      }
      if(that.Months2 != '') {
        y2 = that.formatDate(that.Months2);
      }
      axios({
        method: 'post',
        url: '/admin/customer/order/index', 
        data: {
          page: 1,
          offset:0,
          limit:10,
          type: that.type_Self,
          filter:{
            time:{
              min:y1,
              max:y2,
            },
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })
    },
    Add_leibie() {            //创建导航类别
      var that = this
      var ATT = that.Categ
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ = ATT
    },
     
    NabCH_BTN() {             // 导航确认
        var that = this 
        var ATT = that.Categ
        if (that.dialogVisible3_e == 1) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "order_source", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })  
        } else if(that.dialogVisible3_e == 2) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "order_sale_goods", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })            

        } else if(that.dialogVisible3_e == 3) {
          axios({
            method: 'post',
            url: '/admin/company/content/edit',
            data: {
              field_key: "order_pay_type", //内容标识
              content: ATT
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dialogVisible3 = false
                that.Columns()
              }
          })  
        }
     
    },

    haeDete(index, row) {     // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.Categ
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ = ATT
      }
    },
    haeDete2(index, row) {     // 删除导航类别   --------------全部线索管理
      var that = this
      console.log(row)
      var ATT = that.Categ2
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ2 = ATT
      }
    },
    Chang_ses(e) {             // 切换我的线索
      var that = this
      that.type_Self = e

      that.multipleSelection_id = null
      that.multipleSelection = []
      axios({
        method: 'post',
        url: '/admin/workbench/sales/studyList', 
        data: {
          page: 1,
          offset:0,
          limit:10,
          type: that.type_Self,
          filter:{  

            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })      
    },
    inpu_sech() {             // 搜索
      var that = this
      var y1 = ''
      var y2 = ''
      if(that.Months1 != '') {
        y1 = that.formatDate(that.Months1);
      }
      if(that.Months2 != '') {
        y2 = that.formatDate(that.Months2);
      }
      axios({
        
        method: 'post',
        url: '/admin/workbench/sales/studyList', 
        data: {
          page: 1,
          offset:0,
          limit:10,
          // type: that.type_Self,
          filter:{
            study_type: that.id_three,                               //学时类型
            business_id: that.id_four,                        //业务员ID（服务商）
            site_id: that.id_eight,                            //站点ID
            time:{
              min: y1,
              max: y2,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {   
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
    },

    handleSelect_top (e) {
      if (e == 1) {
        this.$router.push('/AfterSales/Aftershou_A');
      }else if (e == 2) {
        this.$router.push('/AfterSales/Tabs');
      } else if (e == 3) {
        this.$router.push('/AfterSales/Aftershou_C');
      } else if (e == 4) {
        this.$router.push('/AfterSales/Aftershou_D');
      }else if (e == 5) {
        this.$router.push('/AfterSales/Aftershou_D');
      }
    },
      handleSelect(key, keyPath) {  
      var that = this
      if (key == 1) {
        this.info_Show = 0
      } else if(key == 2){
        this.info_Show = 1
      } else if(key == 3){
        this.info_Show = 2
      } else if(key == 4){  
        this.info_Show = 3
      }
    },
    changeSwitch(data,b,index) {      // 是否启用
      var that = this
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl)
    },
    UP_Success2(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl2.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl2)
    },
    HAndemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl.push(element.response.data.url)     
        }
        console.log(that.Row_Eurl)
    },
    HAndemove2(file, fileList) {
      var that = this
      that.Row_Eurl2 = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl2.push(element.response.data.url)   
        }
        console.log(that.Row_Eurl)
    },
    handhange(e) {          // 勾选
      
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
       } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
          that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },

    Add_Del(e) {             //批量操作
        var that = this
        var Att = that.multipleSelection
        if(e == 2) {           
          if (that.multipleSelection_id == null) {      //批量删除
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要删除吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'delete',
              url: '/admin/workbench/sales/delStudy',
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                  that.$message.success(res.data.msg);
                  that.AAAAA()
                }
            }) 
          })
        }
        } else if (e == 5) {
          if (that.multipleSelection_id == null) {      //批量认领
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要批量认领吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'post',
              url: '/admin/customer/order/claim', 
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
              that.$message.success(res.data.msg);
              that.show1 = false
              that.AAAAA()        
            })  
          })
            
          }          
        }
    },


    Handmuiksd2(e) {
      var that = this
      var att = []
      console.log(e) 
      console.log(that.File_Bid)
      for (let i = 0; i < that.File_Bid.length; i++) {
        const element = that.File_Bid[i];
        att.push(element[1])
      }
      that.valueA_5 = att
      console.log(att)
    },
    AAAAA() {
      var that = this 
        axios({
          method: 'post',
          url: '/admin/workbench/sales/studyList', 
          data: {
            page: 1,
            offset:0,
            limit:10,
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })   
    },
    BBBBB() {
      var that = this
        axios.get('/admin/workbench/sales/statisticsColumns',{   // 学时类型
              params:{
                type: 'study' 
              }
            }).then(function (res) {
            if (res.data.code == 0) {
              alert('请求失败');  
            } else {
                 that.BBBBB_list = res.data.data.list.studt_type
                 console.log(that.BBBBB_list)
            }
        })  
    },
    handleCurrentChange1(val) {    // 分页 ···············································
      var that = this
      console.log(val)
      that.currentPage1 = val

      axios({
        method: 'post',
        url: '/admin/customer/order/index', 
        data: {
          page: val,
          offset:0,
          limit:10,
          type: that.type_Self,
          filter:{


            source_id: that.se_id,                        //订单来源id
            goods_id: that.wo_id,                         //销售项目ID
            pay_type: that.de_id,                         //支付方式ID

            site_id: that.te_id,                          // 站点id
            staff_id: that.sa_id,                          // 服务商id
            pay_status: that.st_id,                     //支付状态，10=未支付,20=已支付
            confirm_status: that.co_id,                     //接单状态，10=未接单,20=已接单
            back_status: that.ba_id,                     //退款状态，10=未退款,20=退款中,30=已退款
            pay_form: that.pa_id,                     //付款形式，all=全款,part=部分
    
            money:{
              min:that.Months3,
              max:that.Months4,
            },
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })      

    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },
  },
};
</script>
